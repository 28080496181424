define("ember-svg-jar/inlined/flag.tn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M124 0h496v496H124z\"/></clipPath></defs><g fill-rule=\"evenodd\" clip-path=\"url(#a)\" transform=\"translate(-128) scale(1.0321)\"><path fill=\"#e70013\" d=\"M0 0h744v496H0z\"/><path fill=\"#fff\" d=\"M497.8 247.8a125.1 125.1 0 11-250.2 0 125.1 125.1 0 01250.2 0z\"/><path fill=\"#e70013\" d=\"M372.7 330.8a83 83 0 01-83-83 83 83 0 0183-83c11.4 0 24.5 2.7 33.3 9.2-60.6 2.3-76 53.7-76 74.5s9.8 67 76 73.8a70.4 70.4 0 01-33.3 8.5z\"/><path fill=\"#e70013\" d=\"M445.8 282.7L408.1 269l-24.9 31.4 1.4-40-37.6-14 38.6-11.1 1.7-40 22.4 33.2 38.6-10.8-24.7 31.6z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});