define("ember-svg-jar/inlined/flag.gl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#fff\" d=\"M0 0h512v512H0z\"/><path fill=\"#d00c33\" d=\"M0 256h512v256H0zm53.3 0a170.7 170.7 0 10341.4 0 170.7 170.7 0 00-341.4 0\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});