define("ember-svg-jar/inlined/flag.ee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\" stroke-width=\"1pt\" transform=\"scale(.482 .72)\"><rect width=\"1063\" height=\"708.7\" rx=\"0\" ry=\"0\"/><rect width=\"1063\" height=\"236.2\" y=\"475.6\" fill=\"#fff\" rx=\"0\" ry=\"0\"/><path fill=\"#1291ff\" d=\"M0 0h1063v236.2H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});