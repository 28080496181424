define("ember-svg-jar/inlined/logo.s", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M18.707 0L0 54.561l18.707 54.561h15.656L15.656 54.561 34.363 0zm117.586 0h-15.656l18.707 54.561-18.707 54.561h15.656L155 54.561zM88.444 48.509L75.42 45.097c-6.512-1.7-10.384-4.341-10.384-9.303 0-4.962 4.812-7.913 11.325-7.913 6.042 0 11.164 3.101 12.714 9.614l15.967-5.483c-3.452-10.854-14.086-17.776-28.521-17.776-17.367 0-28.842 8.683-28.842 21.708 0 11.324 6.513 20.008 19.848 23.419l12.715 3.411c8.063 2.011 11.475 4.962 11.475 10.234s-5.122 8.223-12.565 8.223-14.576-3.1-15.977-11.474l-16.666 5.722c3.651 12.115 16.386 19.398 32.172 19.398 17.367 0 30.392-7.913 30.392-22.95 0-11.784-6.672-19.847-20.628-23.418z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 155 109"
    }
  };
  _exports.default = _default;
});