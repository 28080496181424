define("ember-svg-jar/inlined/vertical-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M50 0h50L50 100H0z\"/>",
    "attrs": {
      "fill": "currentColor",
      "viewBox": "0 0 100 100",
      "preserveAspectRatio": "none"
    }
  };
  _exports.default = _default;
});