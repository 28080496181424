define("ember-svg-jar/inlined/flag.bs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M56.6 26.4H537v480.3H56.6z\"/></clipPath></defs><g fill-rule=\"evenodd\" clip-path=\"url(#a)\" transform=\"matrix(1.066 0 0 1.067 -60.4 -28.1)\"><path fill=\"#fff\" d=\"M990 506.2H9.4V27.6H990z\"/><path fill=\"#ffe900\" d=\"M990 370.6H9.4V169.2H990z\"/><path fill=\"#08ced6\" d=\"M990 506.2H9.4V346.7H990zm0-319H9.4V27.9H990z\"/><path d=\"M9 25.9c2.1 0 392.3 237 392.3 237L7.8 505.3 9 25.9z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});