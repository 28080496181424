define("ember-svg-jar/inlined/flag.mn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#c4272f\" d=\"M0 0h512v512H0z\"/><path fill=\"#015197\" d=\"M170.7 0h170.6v512H170.7z\"/><circle cx=\"85.6\" cy=\"215.3\" r=\"28\" fill=\"#f9cf02\"/><circle cx=\"85.6\" cy=\"202.5\" r=\"30.5\" fill=\"#c4272f\"/><path fill=\"#f9cf02\" fill-rule=\"evenodd\" d=\"M73 169.5a12.7 12.7 0 0025.3 0c0-5.1-3.1-5.8-3.1-7.7 0-1.9 1.9-4.4-2-7.6 2 3.2-1.2 3.8-1.2 7s1.2 3.2 1.2 5.7a2.5 2.5 0 01-5 0c0-2.5 2.5-5 2.5-8.9 0-3.8-.6-5-2.5-8.2-2-3.2-5.1-5.8-2.6-8.3-3.8 1.3-1.9 6.4-1.9 9.5 0 3.2-2.5 5.1-2.5 9 0 3.7 1.9 4.4 1.9 7a2.5 2.5 0 01-5.1 0c0-2.6 1.3-2.6 1.3-5.8 0-3.2-3.2-3.8-1.3-7-3.8 3.2-1.9 5.7-1.9 7.6 0 2-3.2 2.6-3.2 7.7z\"/><circle cx=\"85.6\" cy=\"207.6\" r=\"20.4\" fill=\"#f9cf02\"/><path fill=\"#f9cf02\" d=\"M29.6 248.3v122.2h25.5V248.3zm86.6 0v122.2h25.4V248.3zm-56 20.4v10.2H111v-10.2zm0 71.3v10H111v-10zm0-91.7H111l-25.5 15.3zm0 107H111l-25.5 15.2z\"/><circle cx=\"85.6\" cy=\"309.4\" r=\"27\" fill=\"#f9cf02\" stroke=\"#c4272f\" stroke-width=\"3\"/><path fill=\"none\" stroke=\"#c4272f\" stroke-width=\"3\" d=\"M85.6 282.4a13.5 13.5 0 010 27 13.5 13.5 0 100 27\"/><circle cx=\"85.6\" cy=\"295.9\" r=\"5.1\" fill=\"#c4272f\"/><circle cx=\"85.6\" cy=\"322.9\" r=\"5.1\" fill=\"#c4272f\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});