define("ember-svg-jar/inlined/flag.lc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path fill=\"#65cfff\" d=\"M0 0h512v512H0z\"/><path fill=\"#fff\" d=\"M254.8 44.8l173.5 421.6-344 1L254.7 44.8z\"/><path d=\"M255 103l150 362.6-297.5.8L255 103z\"/><path fill=\"#ffce00\" d=\"M254.8 256.1l173.5 210.8-344 .5 170.5-211.3z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});