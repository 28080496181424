define("ember-svg-jar/inlined/flag.in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#f93\" d=\"M0 0h512v170.7H0z\"/><path fill=\"#fff\" d=\"M0 170.7h512v170.6H0z\"/><path fill=\"#128807\" d=\"M0 341.3h512V512H0z\"/><g transform=\"translate(256 256) scale(3.41333)\"><circle r=\"20\" fill=\"#008\"/><circle r=\"17.5\" fill=\"#fff\"/><circle r=\"3.5\" fill=\"#008\"/><g id=\"d\"><g id=\"c\"><g id=\"b\"><g id=\"a\" fill=\"#008\"><circle r=\".9\" transform=\"rotate(7.5 -8.8 133.5)\"/><path d=\"M0 17.5L.6 7 0 2l-.6 5L0 17.5z\"/></g><use width=\"100%\" height=\"100%\" transform=\"rotate(15)\" xlink:href=\"#a\"/></g><use width=\"100%\" height=\"100%\" transform=\"rotate(30)\" xlink:href=\"#b\"/></g><use width=\"100%\" height=\"100%\" transform=\"rotate(60)\" xlink:href=\"#c\"/></g><use width=\"100%\" height=\"100%\" transform=\"rotate(120)\" xlink:href=\"#d\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(-120)\" xlink:href=\"#d\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});