define("ember-svg-jar/inlined/flag.cf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M0 0h512v512H0z\"/></clipPath></defs><g fill-rule=\"evenodd\" clip-path=\"url(#a)\"><path fill=\"#00f\" d=\"M-52-.5h768v127H-52z\"/><path fill=\"#ff0\" d=\"M-52 383.5h768V512H-52z\"/><path fill=\"#009a00\" d=\"M-52 255h768v128.5H-52z\"/><path fill=\"#fff\" d=\"M-52 126.5h768V255H-52z\"/><path fill=\"red\" d=\"M268 0h128v512H268z\"/><path fill=\"#ff0\" d=\"M109.5 112.3L75.9 89.1l-33.4 23.4 11.6-39.2-32.5-24.6 40.7-1L75.7 8.8l13.5 38.6 40.8.8L97.6 73\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});