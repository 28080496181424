define("ember-svg-jar/inlined/flag.mm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"a\" fill=\"#fff\" d=\"M.2 0h-.4L0-.5z\" transform=\"scale(8.844)\"/><g id=\"b\"><use width=\"18\" height=\"12\" transform=\"rotate(-144)\" xlink:href=\"#a\"/><use width=\"18\" height=\"12\" transform=\"rotate(-72)\" xlink:href=\"#a\"/><use width=\"18\" height=\"12\" xlink:href=\"#a\"/><use width=\"18\" height=\"12\" transform=\"rotate(72)\" xlink:href=\"#a\"/><use width=\"18\" height=\"12\" transform=\"rotate(144)\" xlink:href=\"#a\"/></g></defs><path fill=\"#fecb00\" d=\"M0 0h512v170.7H0z\"/><path fill=\"#ea2839\" d=\"M0 341.3h512V512H0z\"/><path fill=\"#34b233\" d=\"M0 170.7h512v170.6H0z\"/><use width=\"18\" height=\"12\" x=\"9\" y=\"6.4\" transform=\"translate(-127.7) scale(42.66667)\" xlink:href=\"#b\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});