define("ember-svg-jar/inlined/flag.co", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\" stroke-width=\"1pt\"><path fill=\"#ffe800\" d=\"M0 0h512v512H0z\"/><path fill=\"#00148e\" d=\"M0 256h512v256H0z\"/><path fill=\"#da0010\" d=\"M0 384h512v128H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});