define("ember-svg-jar/inlined/flag.gh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#006b3f\" d=\"M0 0h512v512H0z\"/><path fill=\"#fcd116\" d=\"M0 0h512v341.3H0z\"/><path fill=\"#ce1126\" d=\"M0 0h512v170.7H0z\"/><path d=\"M256 170.7l55.5 170.6L166.3 236h179.4L200.6 341.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});