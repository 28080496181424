define("ember-svg-jar/inlined/flag.eu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><g id=\"d\"><g id=\"b\"><path id=\"a\" d=\"M0-1l-.3 1 .5.1z\"/><use transform=\"scale(-1 1)\" xlink:href=\"#a\"/></g><g id=\"c\"><use transform=\"rotate(72)\" xlink:href=\"#b\"/><use transform=\"rotate(144)\" xlink:href=\"#b\"/></g><use transform=\"scale(-1 1)\" xlink:href=\"#c\"/></g></defs><path fill=\"#039\" d=\"M0 0h512v512H0z\"/><g fill=\"#fc0\" transform=\"translate(256 258.4) scale(25.28395)\"><use width=\"100%\" height=\"100%\" y=\"-6\" xlink:href=\"#d\"/><use width=\"100%\" height=\"100%\" y=\"6\" xlink:href=\"#d\"/><g id=\"e\"><use width=\"100%\" height=\"100%\" x=\"-6\" xlink:href=\"#d\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(-144 -2.3 -2.1)\" xlink:href=\"#d\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(144 -2.1 -2.3)\" xlink:href=\"#d\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(72 -4.7 -2)\" xlink:href=\"#d\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(72 -5 .5)\" xlink:href=\"#d\"/></g><use width=\"100%\" height=\"100%\" transform=\"scale(-1 1)\" xlink:href=\"#e\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});