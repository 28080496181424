define("ember-svg-jar/inlined/flag.tj", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#060\" d=\"M0 0h512v512H0z\"/><path fill=\"#fff\" d=\"M0 0h512v365.7H0z\"/><path fill=\"#c00\" d=\"M0 0h512v146.3H0z\"/><g fill=\"#f8c300\" transform=\"translate(-256) scale(.73143)\"><path d=\"M672 340.7a12.5 12.5 0 0123.3 5.9v50h9.4v-50a12.5 12.5 0 0123.3-5.9 29.5 29.5 0 10-56 0\"/><path fill=\"#fff\" d=\"M678.7 327.6a20 20 0 0121.3 9.6 20 20 0 0121.3-9.6 21.5 21.5 0 00-42.6 0\"/><path id=\"a\" d=\"M695.3 376.6a38 38 0 01-63.8 24.3 39.5 39.5 0 01-59.8 17.5c3.7 36.4 58.3 29 62.3-6.4 17.2 30.1 55 21.5 66-15.4z\"/><use width=\"100%\" height=\"100%\" transform=\"matrix(-1 0 0 1 1400 0)\" xlink:href=\"#a\"/><path id=\"b\" d=\"M658.8 441.3c-7.6 16.5-22.8 19.3-36.1 6 0 0 5.3-3.8 11-4.8a18 18 0 014.3-14.3 22 22 0 019 11.8c8-1 11.8 1.3 11.8 1.3z\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(9.4 700 804)\" xlink:href=\"#b\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(18.7 700 804)\" xlink:href=\"#b\"/><path fill=\"none\" stroke=\"#f8c300\" stroke-width=\"16\" d=\"M603 478a340 340 0 01194 0\"/><g transform=\"translate(700 380)\"><g transform=\"translate(0 -140)\"><path id=\"c\" d=\"M488533-158734l-790463 574305L0-513674l301930 929245-790463-574305z\" transform=\"scale(.00005)\"/></g><g id=\"d\"><use width=\"100%\" height=\"100%\" transform=\"translate(-70 -121.2)\" xlink:href=\"#c\"/><use width=\"100%\" height=\"100%\" transform=\"translate(-121.2 -70)\" xlink:href=\"#c\"/><use width=\"100%\" height=\"100%\" transform=\"translate(-140)\" xlink:href=\"#c\"/></g><use width=\"100%\" height=\"100%\" transform=\"scale(-1 1)\" xlink:href=\"#d\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});