define("ember-svg-jar/inlined/car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M25.35 6h-3l-.84-2.11A5.65 5.65 0 0016.24.33H9.79a5.65 5.65 0 00-5.27 3.56L3.68 6h-3a.6.6 0 00-.59.75l.3 1.22a.61.61 0 00.59.46h1a3.22 3.22 0 00-1.12 2.43v2.43a3.19 3.19 0 00.81 2.12v2.69a1.63 1.63 0 001.62 1.62H4.9a1.63 1.63 0 001.62-1.62v-1.62h13v1.62a1.63 1.63 0 001.62 1.62h1.62a1.63 1.63 0 001.62-1.62v-2.74a3.19 3.19 0 00.81-2.12v-2.43A3.22 3.22 0 0024 8.38h1a.61.61 0 00.59-.46l.3-1.22a.6.6 0 00-.59-.75l.05.05zM7.53 5.1a2.42 2.42 0 012.26-1.53h6.42a2.42 2.42 0 012.26 1.53l1 2.52h-13L7.53 5.1zM4.9 13.23a1.62 1.62 0 110-3.23 3.12 3.12 0 012.43 2.42c0 1-1.46.81-2.43.81zm16.2 0c-1 0-2.43.16-2.43-.81A3.12 3.12 0 0121.1 10a1.62 1.62 0 110 3.23z\"/>",
    "attrs": {
      "viewBox": "0 0 26 20"
    }
  };
  _exports.default = _default;
});