define("ember-svg-jar/inlined/flag.gr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#005bae\" fill-rule=\"evenodd\" stroke-width=\".8\" d=\"M0 0h512v57H0z\"/><path fill=\"#fff\" fill-rule=\"evenodd\" stroke-width=\".8\" d=\"M0 57h512v57H0z\"/><path fill=\"#005bae\" fill-rule=\"evenodd\" stroke-width=\".8\" d=\"M0 114h512v57H0z\"/><path fill=\"#fff\" fill-rule=\"evenodd\" stroke-width=\".8\" d=\"M0 171h512v57H0z\"/><path fill=\"#005bae\" fill-rule=\"evenodd\" stroke-width=\".8\" d=\"M0 228h512v56.9H0z\"/><path fill=\"#fff\" fill-rule=\"evenodd\" stroke-width=\".8\" d=\"M0 284.9h512v57H0z\"/><path fill=\"#005bae\" fill-rule=\"evenodd\" stroke-width=\".8\" d=\"M0 341.9h512v57H0z\"/><path fill=\"#fff\" fill-rule=\"evenodd\" stroke-width=\".8\" d=\"M0 398.9h512v57H0z\"/><path fill=\"#005bae\" stroke-width=\"3\" d=\"M0 0h284.9v284.9H0z\"/><g fill=\"#fff\" fill-rule=\"evenodd\" stroke-width=\"1.3\"><path d=\"M113.96 0h56.98v284.9h-56.98z\"/><path d=\"M0 113.96h284.9v56.98H0z\"/></g><path fill=\"#005bae\" fill-rule=\"evenodd\" stroke-width=\".8\" d=\"M0 455h512v57H0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});