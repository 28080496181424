define("ember-svg-jar/inlined/flag.is", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M85.4 0h486v486h-486z\"/></clipPath></defs><g fill-rule=\"evenodd\" stroke-width=\"0\" clip-path=\"url(#a)\" transform=\"translate(-90) scale(1.0535)\"><path fill=\"#003897\" d=\"M0 0h675v486H0z\"/><path fill=\"#fff\" d=\"M0 189h189V0h108v189h378v108H297v189H189V297H0V189z\"/><path fill=\"#d72828\" d=\"M0 216h216V0h54v216h405v54H270v216h-54V270H0v-54z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});