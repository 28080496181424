define("ember-svg-jar/inlined/flag.tt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#fff\" d=\"M0 0h512v512H0z\" style=\"width:0\"/><g fill-rule=\"evenodd\"><path fill=\"#e00000\" d=\"M371 512L0 1v510.7l371 .3zM141 0l371 511V.2L141 0z\"/><path d=\"M22.2.2h94.9l374.5 511.3h-97.9L22.2.2z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});