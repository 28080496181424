define("ember-svg-jar/inlined/flag.bi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill=\"gray\" d=\"M60.8 337h175v175h-175z\"/></clipPath></defs><g fill-rule=\"evenodd\" clip-path=\"url(#a)\" transform=\"translate(-178 -986) scale(2.9257)\"><path fill=\"#00cf00\" d=\"M0 337l146.6 87.5L0 512zm293.1 0l-146.5 87.5L293 512z\"/><path fill=\"red\" d=\"M0 337l146.6 87.5L293 337zm0 175l146.6-87.5L293 512z\"/><path fill=\"#fff\" d=\"M293.1 337h-27.3L0 495.7V512h27.3l265.8-158.7z\"/><path fill=\"#fff\" d=\"M197.2 424.5a50.6 50.6 0 11-101.2 0 50.6 50.6 0 01101.2 0z\"/><path fill=\"#fff\" d=\"M0 337v16.3L265.8 512h27.3v-16.3L27.3 337z\"/><path fill=\"red\" stroke=\"#00de00\" stroke-width=\"1pt\" d=\"M156.5 405.4l-6.6.1-3.4 5.6-3.4-5.6-6.5-.1 3.2-5.8-3.2-5.7 6.6-.2 3.4-5.6 3.4 5.7h6.5l-3.1 5.8zm-22 38.2h-6.6l-3.4 5.7-3.4-5.6-6.6-.2 3.2-5.7-3.1-5.8 6.5-.1 3.4-5.6 3.4 5.6 6.6.2-3.2 5.7zm44.6 0h-6.6l-3.4 5.7-3.4-5.6-6.5-.2 3.1-5.7-3.1-5.8 6.6-.1 3.4-5.6 3.4 5.6 6.5.2-3.2 5.7z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});