define("ember-svg-jar/inlined/drip.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M30.327-124V95.697c-.14 1.074-.327 2.133-.327 3.244v70.711c0 13.808 11.192 25 25 25 13.807 0 25-11.192 25-25v-110.9c0-13.807 11.193-25 25-25s25 11.193 25 25v205.996c0 13.807 11.193 25 25 25 13.808 0 25-11.193 25-25V139.131c0-13.808 11.193-25 25-25s25 11.192 25 25v39.97h.022c0 .075-.022.144-.022.22v145.711c0 13.806 11.193 25 25 25s25-11.194 25-25v-145.71c0-.076-.022-.145-.022-.22H280V98.94c0-13.806 11.193-25 25-25 13.808 0 25 11.194 25 25v30.523c0 13.807 11.193 25 25 25 13.808 0 25-11.193 25-25V98.94c0-13.805 11.194-25 25-25 13.807 0 25 11.195 25 25v125.618c0 13.808 11.194 25 25 25 13.807 0 24.999-11.192 24.999-25V-124H30.327z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 480 480"
    }
  };
  _exports.default = _default;
});