define("ember-svg-jar/inlined/flag.st", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#12ad2b\" d=\"M0 0h512v512H0z\"/><path fill=\"#ffce00\" d=\"M0 146.3h512v219.4H0z\"/><path fill=\"#d21034\" d=\"M0 0v512l192-256\"/><g id=\"c\" transform=\"translate(276.9 261.5) scale(.33167)\"><g id=\"b\"><path id=\"a\" d=\"M0-200V0h100\" transform=\"rotate(18 0 -200)\"/><use width=\"100%\" height=\"100%\" transform=\"scale(-1 1)\" xlink:href=\"#a\"/></g><use width=\"100%\" height=\"100%\" transform=\"rotate(72)\" xlink:href=\"#b\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(144)\" xlink:href=\"#b\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(-144)\" xlink:href=\"#b\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(-72)\" xlink:href=\"#b\"/></g><use width=\"100%\" height=\"100%\" x=\"700\" transform=\"translate(-550.9)\" xlink:href=\"#c\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});