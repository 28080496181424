define("ember-svg-jar/inlined/flag.ax", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M166 0h850v850H166z\"/></clipPath></defs><g clip-path=\"url(#a)\" transform=\"translate(-100) scale(.6024)\"><path fill=\"#0053a5\" d=\"M0 0h1300v850H0z\"/><g fill=\"#ffce00\"><path d=\"M400 0h250v850H400z\"/><path d=\"M0 300h1300v250H0z\"/></g><g fill=\"#d21034\"><path d=\"M475 0h100v850H475z\"/><path d=\"M0 375h1300v100H0z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});