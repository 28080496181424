define("ember-svg-jar/inlined/flag.uz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#1eb53a\" d=\"M0 341.3h512V512H0z\"/><path fill=\"#0099b5\" d=\"M0 0h512v170.7H0z\"/><path fill=\"#ce1126\" d=\"M0 163.8h512v184.4H0z\"/><path fill=\"#fff\" d=\"M0 174h512v164H0z\"/><circle cx=\"143.4\" cy=\"81.9\" r=\"61.4\" fill=\"#fff\"/><circle cx=\"163.8\" cy=\"81.9\" r=\"61.4\" fill=\"#0099b5\"/><g fill=\"#fff\" transform=\"translate(278.5 131) scale(2.048)\"><g id=\"e\"><g id=\"d\"><g id=\"c\"><g id=\"b\"><path id=\"a\" d=\"M0-6L-1.9-.3 1 .7\"/><use width=\"100%\" height=\"100%\" transform=\"scale(-1 1)\" xlink:href=\"#a\"/></g><use width=\"100%\" height=\"100%\" transform=\"rotate(72)\" xlink:href=\"#b\"/></g><use width=\"100%\" height=\"100%\" transform=\"rotate(-72)\" xlink:href=\"#b\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(144)\" xlink:href=\"#c\"/></g><use width=\"100%\" height=\"100%\" y=\"-24\" xlink:href=\"#d\"/><use width=\"100%\" height=\"100%\" y=\"-48\" xlink:href=\"#d\"/></g><use width=\"100%\" height=\"100%\" x=\"24\" xlink:href=\"#e\"/><use width=\"100%\" height=\"100%\" x=\"48\" xlink:href=\"#e\"/><use width=\"100%\" height=\"100%\" x=\"-48\" xlink:href=\"#d\"/><use width=\"100%\" height=\"100%\" x=\"-24\" xlink:href=\"#d\"/><use width=\"100%\" height=\"100%\" x=\"-24\" y=\"-24\" xlink:href=\"#d\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});