define("ember-svg-jar/inlined/flag.kw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M0 0h496v496H0z\"/></clipPath></defs><g fill-rule=\"evenodd\" stroke-width=\"1pt\" clip-path=\"url(#a)\" transform=\"scale(1.0321)\"><path fill=\"#fff\" d=\"M0 165.3h992.1v165.4H0z\"/><path fill=\"#f31830\" d=\"M0 330.7h992.1v165.4H0z\"/><path fill=\"#00d941\" d=\"M0 0h992.1v165.4H0z\"/><path d=\"M0 0v496l247.5-165.3.5-165.5L0 0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});