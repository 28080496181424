define("ember-svg-jar/inlined/flag.sc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M0 0h512v496H0z\"/></clipPath></defs><g fill-rule=\"evenodd\" stroke-width=\"1pt\" clip-path=\"url(#a)\" transform=\"scale(1 1.0321)\"><path fill=\"red\" d=\"M0 0h992.1v496H0z\"/><path fill=\"#090\" d=\"M0 496l992.1-165.3v165.4H0z\"/><path fill=\"#fff\" d=\"M0 496l992.1-330.6v165.3L0 496.1z\"/><path fill=\"#009\" d=\"M0 496V0h330.7L0 496z\"/><path fill=\"#ff0\" d=\"M0 496L330.7 0h330.7L0 496z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});