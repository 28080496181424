define("ember-svg-jar/inlined/flag.ga", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path fill=\"#ffe700\" d=\"M512 512H0V0h512z\"/><path fill=\"#36a100\" d=\"M512 170.7H0V0h512z\"/><path fill=\"#006dbc\" d=\"M512 512H0V341.3h512z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});