define("ember-svg-jar/inlined/flag.ma", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#c1272d\" d=\"M512 0H0v512h512z\"/><path fill=\"none\" stroke=\"#006233\" stroke-width=\"12.5\" d=\"M256 191.4l-38 116.8 99.4-72.2H194.6l99.3 72.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});