define("ember-svg-jar/inlined/flag.jm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M0 0l256 256L0 512zm512 0L256 256l256 256z\"/><path fill=\"#090\" d=\"M0 0l256 256L512 0zm0 512l256-256 256 256z\"/><path fill=\"#fc0\" d=\"M512 0h-47.7L0 464.3V512h47.7L512 47.7z\"/><path fill=\"#fc0\" d=\"M0 0v47.7L464.3 512H512v-47.7L47.7 0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});