define("ember-svg-jar/inlined/flag.cw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M0 0h9000v9000H0z\"/></clipPath><path id=\"b\" d=\"M0-1l.2.7H1L.3 0l.2.7L0 .4l-.6.4.2-.7-.5-.4h.7z\"/></defs><g clip-path=\"url(#a)\" transform=\"scale(.057)\"><path fill=\"#002b7f\" d=\"M0 0h13500v9000H0z\"/><path fill=\"#f9e814\" d=\"M0 5625h13500v1125H0z\"/><use width=\"13500\" height=\"9000\" x=\"2\" y=\"2\" fill=\"#fff\" transform=\"scale(750)\" xlink:href=\"#b\"/><use width=\"13500\" height=\"9000\" x=\"3\" y=\"3\" fill=\"#fff\" transform=\"scale(1000)\" xlink:href=\"#b\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});