define("ember-svg-jar/inlined/flag.cg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M115.7 0h496.1v496h-496z\"/></clipPath></defs><g fill-rule=\"evenodd\" stroke-width=\"1pt\" clip-path=\"url(#a)\" transform=\"translate(-119.5) scale(1.032)\"><path fill=\"#ff0\" d=\"M0 0h744v496H0z\"/><path fill=\"#00ca00\" d=\"M0 0v496L496 0H0z\"/><path fill=\"red\" d=\"M248 496h496V0L248 496z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});