define("ember-svg-jar/inlined/flag.km", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M0 0h416.3v416.3H0z\"/></clipPath></defs><g fill-rule=\"evenodd\" clip-path=\"url(#a)\" transform=\"scale(1.23)\"><path fill=\"#ff0\" d=\"M0 0h625v104H0z\"/><path fill=\"#fff\" d=\"M0 104h625v104.1H0z\"/><path fill=\"#be0027\" d=\"M0 208.1h625v104H0z\"/><path fill=\"#3b5aa3\" d=\"M0 312.2h625v104H0z\"/><path fill=\"#239e46\" d=\"M0 0v416.2l310.4-207.5L0 0z\"/><path fill=\"#fff\" d=\"M127.8 115c-69.2-3.5-100.7 51.6-100.6 94.2-.2 50.4 47.6 92 91.7 89.4A100 100 0 0165.8 209a98.3 98.3 0 0162-94z\"/><path fill=\"#fff\" d=\"M126.8 160.2l-9.8-7.6-11.8 3.7 4.2-11.6-7.1-10.1 12.3.4 7.4-10 3.4 12 11.8 3.9-10.3 7zm-.2 42.3l-9.8-7.6-11.8 3.7 4.2-11.6-7.2-10.1 12.4.4 7.4-10 3.4 12 11.8 4-10.3 6.9zm.2 42.8l-9.8-7.6-11.8 3.7 4.2-11.7-7.1-10 12.3.4 7.4-10 3.4 12 11.8 3.9-10.3 6.9zm-.2 43.1l-9.8-7.6-11.8 3.7 4.2-11.6-7.2-10.1 12.4.4 7.4-10 3.4 12 11.8 4-10.3 6.9z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});