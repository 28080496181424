define("ember-svg-jar/inlined/flag.au", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#006\" stroke-width=\"1.3\" d=\"M0 0h512v512H0z\"/><path fill=\"#fff\" fill-rule=\"evenodd\" stroke-width=\"1.3\" d=\"M54.9 368.6L95.5 384l13.4-41.4 13.3 41.4 40.7-15.4-24.1 36.3 37.4 22.2-43.3 3.7 6 43.1-30-31.5-30 31.5 6-43-43.4-3.8L79 404.9m325 71.5l-19 1.6 2.7 18.8-13-13.7-13 13.7L364 478l-18.8-1.6 16.3-9.6L351 451l17.7 6.7 5.8-18 5.7 18L398 451l-10.4 15.8m16.2-270.4L385 198l2.6 18.8-13-13.7-13 13.7L364 198l-18.8-1.6 16.3-9.6L351 171l17.7 6.7 5.8-18 5.7 18L398 171l-10.4 15.8m-88.8 123.4l-18.8 1.6 2.6 18.7-13-13.7-13 13.7 2.5-18.7-18.8-1.6 16.3-9.7-10.5-15.7 17.7 6.7 5.8-18 5.7 18 17.7-6.7-10.4 15.7M497 282.2l-18.8 1.6 2.6 18.7-13-13.7-13 13.7 2.5-18.7-18.8-1.6 16.3-9.7-10.5-15.7 17.7 6.7 5.8-18 5.8 18 17.6-6.7-10.4 15.7M416.6 355l-10.3 6.4 2.9-11.8-9.3-7.8 12-.9 4.7-11.2L421 341l12.1 1-9.2 7.7 2.9 11.8\"/><path fill=\"#006\" d=\"M0 0h256v256H0z\"/><path fill=\"#fff\" d=\"M256 0v32l-95 96 95 93.5V256h-33.5L127 162l-93 94H0v-34l93-93.5L0 37V0h31l96 94 93-94z\"/><path fill=\"#c8102e\" d=\"M92 162l5.5 17L21 256H0v-1.5zm62-6l27 4 75 73.5V256zM256 0l-96 98-2-22 75-76zM0 .5L96.5 95 67 91 0 24.5z\"/><path fill=\"#fff\" d=\"M88 0v256h80V0zM0 88v80h256V88z\"/><path fill=\"#c8102e\" d=\"M0 104v48h256v-48zM104 0v256h48V0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});