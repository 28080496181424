define("ember-svg-jar/inlined/flag.dz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#fff\" d=\"M256 0h256v512H256z\"/><path fill=\"#006233\" d=\"M0 0h256v512H0z\"/><path fill=\"#d21034\" d=\"M367 192a128 128 0 100 128 102.4 102.4 0 110-128m4.2 64L256 218.4l71.7 98.2V195.4L256 293.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});