define("ember-svg-jar/inlined/flag.cm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#007a5e\" d=\"M0 0h170.7v512H0z\"/><path fill=\"#ce1126\" d=\"M170.7 0h170.6v512H170.7z\"/><path fill=\"#fcd116\" d=\"M341.3 0H512v512H341.3z\"/><g fill=\"#fcd116\" transform=\"translate(256 256) scale(5.6889)\"><g id=\"b\"><path id=\"a\" d=\"M0-8L-2.5-.4 1.3.9z\"/><use width=\"100%\" height=\"100%\" transform=\"scale(-1 1)\" xlink:href=\"#a\"/></g><use width=\"100%\" height=\"100%\" transform=\"rotate(72)\" xlink:href=\"#b\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(144)\" xlink:href=\"#b\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(-144)\" xlink:href=\"#b\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(-72)\" xlink:href=\"#b\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});