define("ember-svg-jar/inlined/flag.pa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M0 0h512v512H0z\"/></clipPath></defs><g fill-rule=\"evenodd\" clip-path=\"url(#a)\"><path fill=\"#fff\" d=\"M-26-25h592.5v596H-26z\"/><path fill=\"#db0000\" d=\"M255.3-20.4h312.1v275.2h-312z\"/><path fill=\"#0000ab\" d=\"M-54.5 254.8h309.9V571H-54.5zM179 181.6l-46.5-29.2-46.2 29.5 17.2-48-46.2-29.6 57.1-.4 17.7-47.8 18.1 47.7h57.1l-45.9 30 17.6 47.8z\"/><path fill=\"#d80000\" d=\"M435.2 449l-46.4-29.2-46.3 29.5 17.2-48-46.2-29.5 57.2-.4 17.7-47.8 18 47.7h57.2l-46 30 17.6 47.7z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});