define("ember-svg-jar/inlined/flag.za", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M70.1 0h499.6v499.6H70.1z\"/></clipPath></defs><g clip-path=\"url(#a)\" transform=\"translate(-71.9) scale(1.0248)\"><g fill-rule=\"evenodd\" stroke-width=\"1pt\"><path d=\"M0 397.9v-296l220.4 147.9L0 397.9z\"/><path fill=\"#00c\" d=\"M150.4 499.7l247.4-166.5h351.6v166.5h-599z\"/><path fill=\"red\" d=\"M134.5 0h615v166.6H397.7S137.8-1.6 134.5 0z\"/><path fill=\"#fc0\" d=\"M0 62.5v39.3l220.4 148L0 397.8v39.4l277.6-187.4L0 62.5z\"/><path fill=\"#093\" d=\"M0 62.5V0h92.6l294 199h362.8v101.7H386.6l-294 198.9H0v-62.4l277.6-187.4L0 62.5z\"/><path fill=\"#fff\" d=\"M92.6 0h57.8l247.4 166.6h351.6V199H386.6L92.6 0zm0 499.7h57.8l247.4-166.5h351.6v-32.4H386.6l-294 198.8z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});