define("ember-svg-jar/inlined/drip.4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M479.673-117V63.477c.14 1.073.327 2.132.327 3.243v70.712c0 13.808-11.192 25-25 25-13.807 0-25-11.192-25-25v-71.68c0-13.807-11.193-25-25-25s-25 11.193-25 25v349.805c0 13.807-11.193 25-25 25-13.808 0-25-11.193-25-25V146.131c0-13.808-11.193-25-25-25s-25 11.192-25 25v39.97h-.022c0 .075.022.144.022.22v-12.14 40.19c0 13.805-11.193 25-25 25s-25-11.195-25-25v-80.38 52.33c0-.076.022-.145.022-.22H230v-80.16c0-13.806-11.193-25-25-25-13.808 0-25 11.194-25 25v56.67c0 13.807-11.193 25-25 25-13.808 0-25-11.193-25-25v-56.67c0-13.806-11.194-25-25-25-13.807 0-25 11.194-25 25v177.911c0 13.808-11.194 25-25 25-13.807 0-24.999-11.192-24.999-25V-117h449.673z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 480 480"
    }
  };
  _exports.default = _default;
});