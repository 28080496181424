define("ember-svg-jar/inlined/flag.ly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path d=\"M250 12h500v500H250z\"/></clipPath></defs><g clip-path=\"url(#a)\" transform=\"translate(-256 -12.3) scale(1.024)\"><path fill=\"#239e46\" d=\"M0 12h1000v500H0z\"/><path d=\"M0 12h1000v375H0z\"/><path fill=\"#e70013\" d=\"M0 12h1000v125H0z\"/><path fill=\"#fff\" d=\"M544.2 217.8a54.3 54.3 0 100 88.4 62.5 62.5 0 110-88.4M530.4 262l84.1-27.3-52 71.5v-88.4l52 71.5z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});