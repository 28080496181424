define("ember-svg-jar/inlined/flag.ve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><g id=\"d\" transform=\"translate(0 -36)\"><g id=\"c\"><g id=\"b\"><path id=\"a\" fill=\"#fff\" d=\"M0-5L-1.5-.2l2.8.9z\"/><use width=\"180\" height=\"120\" transform=\"scale(-1 1)\" xlink:href=\"#a\"/></g><use width=\"180\" height=\"120\" transform=\"rotate(72)\" xlink:href=\"#b\"/></g><use width=\"180\" height=\"120\" transform=\"rotate(-72)\" xlink:href=\"#b\"/><use width=\"180\" height=\"120\" transform=\"rotate(144)\" xlink:href=\"#c\"/></g></defs><path fill=\"#cf142b\" d=\"M0 0h512v512H0z\"/><path fill=\"#00247d\" d=\"M0 0h512v341.3H0z\"/><path fill=\"#fc0\" d=\"M0 0h512v170.7H0z\"/><g id=\"f\" transform=\"translate(256.3 358.4) scale(4.265)\"><g id=\"e\"><use width=\"180\" height=\"120\" transform=\"rotate(10)\" xlink:href=\"#d\"/><use width=\"180\" height=\"120\" transform=\"rotate(30)\" xlink:href=\"#d\"/></g><use width=\"180\" height=\"120\" transform=\"rotate(40)\" xlink:href=\"#e\"/></g><use width=\"180\" height=\"120\" transform=\"rotate(-80 256.3 358.4)\" xlink:href=\"#f\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});