define("ember-svg-jar/inlined/flag.bg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\" stroke-width=\"1pt\"><path fill=\"#d62612\" d=\"M0 341.3h512V512H0z\"/><path fill=\"#fff\" d=\"M0 0h512v170.7H0z\"/><path fill=\"#00966e\" d=\"M0 170.7h512v170.6H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});