define("ember-svg-jar/inlined/flag.mv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#d21034\" d=\"M0 0h512v512H0z\"/><path fill=\"#007e3a\" d=\"M128 128h256v256H128z\"/><circle cx=\"288\" cy=\"256\" r=\"85.3\" fill=\"#fff\"/><ellipse cx=\"308.6\" cy=\"256\" fill=\"#007e3a\" rx=\"73.9\" ry=\"85.3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});