define("ember-svg-jar/inlined/flag.na", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M0 0h512v512H0z\"/></clipPath></defs><g fill-rule=\"evenodd\" clip-path=\"url(#a)\"><path fill=\"#fff\" d=\"M0 0h512v512H0z\"/><path fill=\"#3662a2\" d=\"M-108.2.2l.8 368.6L466.6 0l-574.8.2z\"/><path fill=\"#38a100\" d=\"M630.7 511.5l-1.4-383.2-579 383.5 580.4-.3z\"/><path fill=\"#c70000\" d=\"M-107.9 396.6l.5 115.4 125.3-.2 611.7-410.1L629 1.4 505.2.2l-613 396.4z\"/><path fill=\"#ffe700\" d=\"M154 183.4l-23.1-14-13.4 23.6-13-23.8L81 183l.6-27.1-27 .2 14-23.2L45 119.5l23.8-13L55 83l27 .6-.1-27.1 23.2 14 13.4-23.6 13 23.7L155.2 57l-.6 27 27-.1-14 23.2 23.6 13.3-23.8 13.1 13.7 23.4-27-.5z\"/><path fill=\"#3662a2\" d=\"M167.8 120c0 27.2-22.3 49.3-49.8 49.3s-49.7-22.1-49.7-49.4 22.3-49.3 49.8-49.3 49.7 22 49.7 49.3z\"/><path fill=\"#ffe700\" d=\"M157 120a39 39 0 11-77.9 0 39 39 0 0177.9 0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});