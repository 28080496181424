define("ember-svg-jar/inlined/flag.cd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0-88h600v600H0z\"/></clipPath></defs><g clip-path=\"url(#a)\" transform=\"matrix(.853 0 0 .853 0 75.1)\"><path fill=\"#007fff\" d=\"M0-88h800v600H0z\"/><path fill=\"#f7d618\" d=\"M36 32h84l26-84 26 84h84l-68 52 26 84-68-52-68 52 26-84-68-52zM750-88L0 362v150h50L800 62V-88h-50\"/><path fill=\"#ce1021\" d=\"M800-88L0 392v120L800 32V-88\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});