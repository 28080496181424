define("ember-svg-jar/inlined/flag.at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path fill=\"#fff\" d=\"M512 512H0V0h512z\"/><path fill=\"#ed2939\" d=\"M512 512H0V341.3h512zm0-341.2H0V.1h512z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});