define("ember-svg-jar/inlined/flag.ch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\" stroke-width=\"1pt\"><path fill=\"#d52b1e\" d=\"M0 0h512v512H0z\"/><g fill=\"#fff\"><path d=\"M96 208h320v96H96z\"/><path d=\"M208 96h96v320h-96z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});