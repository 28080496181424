define("ember-svg-jar/inlined/flag.td", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path fill=\"#000067\" d=\"M0 0h171.2v512H0z\"/><path fill=\"red\" d=\"M340.8 0H512v512H340.8z\"/><path fill=\"#ff0\" d=\"M171.2 0h169.6v512H171.2z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});