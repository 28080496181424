define("ember-svg-jar/inlined/flag.sr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#377e3f\" d=\"M0 0h512v512H0z\"/><path fill=\"#fff\" d=\"M0 102.4h512v307.2H0z\"/><path fill=\"#b40a2d\" d=\"M0 153.6h512v204.8H0z\"/><path fill=\"#ecc81d\" d=\"M255.9 163.4l60.2 185.2-157.6-114.5h194.8L195.7 348.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});