define("ember-svg-jar/inlined/flag.es-ct", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#fcdd09\" d=\"M0 0h512v512H0z\"/><path stroke=\"#da121a\" stroke-width=\"60\" d=\"M0 90h810m0 120H0m0 120h810m0 120H0\" transform=\"scale(.6321 .94815)\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});