define("ember-svg-jar/inlined/flag.tv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#009fca\" stroke-width=\"4.9\" d=\"M0 0h512v512H0z\"/><path fill=\"#fff40d\" fill-rule=\"evenodd\" stroke-width=\"1pt\" d=\"M478 226.3l7.1 20.4-18.4-12.6-18.5 12.6 7.1-20.4-18.5-12.6h22.9l7-20.4 7 20.4h22.8zm-57.2 162.8l7 20.4-18.4-12.7-18.4 12.7 7-20.4-18.4-12.7h22.8l7-20.4 7 20.5h22.9zm57.3-36.9l7 20.4-18.4-12.6-18.5 12.6 7.1-20.4-18.5-12.6h22.9l7-20.4 7 20.4h22.8zm-246.2 118l7.1 20.5-18.4-12.7-18.5 12.7 7.1-20.4-18.5-12.6h22.8l7-20.4 7.1 20.4h22.8zm43.1-88.4l-7-20.4 18.4 12.6 18.4-12.6-7 20.4 18.4 12.6h-22.8l-7 20.4-7-20.4h-22.8zm67.3-93l-7.1-20.4 18.4 12.6 18.5-12.6-7 20.4 18.4 12.6h-22.8l-7 20.4-7-20.4h-22.9zm56.5-19.3l-7-20.4 18.4 12.6 18.4-12.6-7 20.4 18.4 12.6h-22.8l-7 20.4-7-20.4h-22.9zm-56.5 161l-7.1-20.4 18.4 12.6 18.5-12.6-7 20.4 18.4 12.6h-22.8l-7 20.4-7-20.4h-22.9zM275 446.9l-7-20.4 18.4 12.6 18.4-12.6-7 20.4 18.4 12.6h-22.8l-7 20.4-7-20.4h-22.8z\"/><path fill=\"#012169\" d=\"M0 0h256v256H0z\"/><path fill=\"#FFF\" d=\"M256 0v32l-95 96 95 93.5V256h-33.5L127 162l-93 94H0v-34l93-93.5L0 37V0h31l96 94 93-94z\"/><path fill=\"#C8102E\" d=\"M92 162l5.5 17L21 256H0v-1.5zm62-6l27 4 75 73.5V256zM256 0l-96 98-2-22 75-76zM0 .5L96.5 95 67 91 0 24.5z\"/><path fill=\"#FFF\" d=\"M88 0v256h80V0zM0 88v80h256V88z\"/><path fill=\"#C8102E\" d=\"M0 104v48h256v-48zM104 0v256h48V0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});