define("ember-svg-jar/inlined/flag.nc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#009543\" d=\"M0 0h512v512H0z\"/><path fill=\"#ed4135\" d=\"M0 0h512v341.3H0z\"/><path fill=\"#0035ad\" d=\"M0 0h512v170.7H0z\"/><circle cx=\"225.6\" cy=\"256\" r=\"167.8\" fill=\"#fae600\" stroke=\"#000\" stroke-width=\"5.7\"/><path stroke=\"#000\" stroke-width=\"6.8\" d=\"M197.2 281h56.9m-56.9-42h56.9M225.6 88.6v286.8\"/><path d=\"M158 410c68.6 28 132.8 1.8 132.8 1.8s-24.3-26.2-36.7-36.5c-12.1-10-47.8-9.6-60 0-10 8-39.2 37.5-36 34.8z\"/><ellipse cx=\"225.6\" cy=\"333.4\" rx=\"18.8\" ry=\"27.3\"/><ellipse cx=\"225.6\" cy=\"260\" rx=\"22.8\" ry=\"14.4\"/><circle cx=\"225.6\" cy=\"193.4\" r=\"22.8\"/><path d=\"M253 108.7s2 3.2-2.2 10.7c-19.9 35.7-39.7 36.5-43.5 39.5-4.2 3.4-6 3.2-6 3.2.4-3 .6-15.6.8-16.8 3-16.6 28.3-16.4 48-33.5 3-2.7 2.8-3.2 2.8-3.2zm-66.6 76.8s4.5 12.7 5.1 25.6c1.1 20.4 20.7 21 34.1 21v-11.4c-10 0-18.9-1.4-26.1-16.5a130.8 130.8 0 00-13.1-18.7zm-.6 165s7.2-9 15.6-29.6a27 27 0 0124.2-17.1v-16c-21.6 0-32 8-33 19.9-2.4 24-6.8 42.7-6.8 42.7z\"/><use width=\"100%\" height=\"100%\" transform=\"matrix(-1 0 0 1 451.3 0)\" xlink:href=\"#leaf\"/><path d=\"M265 185.5s-4.6 12.7-5.2 25.6c-1.1 20.5-20.7 21-34.1 21v-11.3c10.1 0 18.9-1.5 26.2-16.5 3.8-6.7 8.2-13 13-18.8zm.5 165s-7.1-8.9-15.6-29.6a27 27 0 00-24.2-17v-16c21.6 0 32 8 33.1 19.8a351 351 0 006.7 42.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});