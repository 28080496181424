define("ember-svg-jar/inlined/flag.bj", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill=\"gray\" d=\"M67.6-154h666v666h-666z\"/></clipPath></defs><g clip-path=\"url(#a)\" transform=\"translate(-52 118.4) scale(.7688)\"><g fill-rule=\"evenodd\" stroke-width=\"1pt\"><path fill=\"#319400\" d=\"M0-154h333v666H0z\"/><path fill=\"#ffd600\" d=\"M333-154h666v333H333z\"/><path fill=\"#de2110\" d=\"M333 179h666v333H333z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});