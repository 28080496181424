define("ember-svg-jar/inlined/flag.ag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill=\"#25ff01\" d=\"M109 47.6h464.8v464.9H109z\"/></clipPath></defs><g fill-rule=\"evenodd\" clip-path=\"url(#a)\" transform=\"translate(-120 -52.4) scale(1.1014)\"><path fill=\"#fff\" d=\"M0 47.6h693V512H0z\"/><path d=\"M1.5 48.2h690.9v196.2H1.5z\"/><path fill=\"#0061ff\" d=\"M128.3 232.1h458.5v103.4H128.3z\"/><path fill=\"#e20000\" d=\"M692.5 49.2v463.3H347L692.5 49.2zm-691.3 0v463.3h345.7L1.2 49.2z\"/><path fill=\"#ffd600\" d=\"M508.8 232.2l-69.3-17.6 59-44.4-72.5 10.3 37.3-63-64.1 37.2 11.3-73.5-43.4 58-17.6-67.3-19.6 69.3-43.4-59 12.4 75.6-64.1-39.3 37.2 63-70.3-11.3 57.9 43.4-72.4 18.6h321.6z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});