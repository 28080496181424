define("ember-svg-jar/inlined/flag.my", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#cc0001\" d=\"M0 0h512v512H0z\"/><path id=\"a\" fill=\"#fff\" d=\"M0 475.5h512V512H0z\"/><use width=\"100%\" height=\"100%\" transform=\"translate(0 -73.1)\" xlink:href=\"#a\"/><use width=\"100%\" height=\"100%\" transform=\"translate(0 -146.3)\" xlink:href=\"#a\"/><use width=\"100%\" height=\"100%\" transform=\"translate(0 -219.4)\" xlink:href=\"#a\"/><use width=\"100%\" height=\"100%\" transform=\"translate(0 -292.6)\" xlink:href=\"#a\"/><use width=\"100%\" height=\"100%\" transform=\"translate(0 -365.7)\" xlink:href=\"#a\"/><use width=\"100%\" height=\"100%\" transform=\"translate(0 -438.9)\" xlink:href=\"#a\"/><path fill=\"#010066\" d=\"M0 0h320v292.6H0z\"/><g fill=\"#fc0\"><path d=\"M123.8 51.9a93 93 0 00-93.2 92.9 93 93 0 00143.7 78.1 82.7 82.7 0 01-27 4.5 82.5 82.5 0 01-82.7-82.3c0-45.5 37-82.4 82.6-82.4a82.6 82.6 0 0128.7 5A93 93 0 00124 52z\"/><path d=\"M278.4 193.2L239 172.7l11.6 41.9-26.2-35-8.2 42.6-8-42.7-26.5 35 11.8-41.9-39.3 20.4 29.2-32.8-44.5 1.7 40.9-17.1-40.9-17.3 44.5 1.8-29-32.9 39.2 20.5L182 75l26.2 35 8.2-42.6 8 42.7L251 75l-11.8 42 39.3-20.4-29.2 32.8 44.5-1.7-40.9 17.2 40.9 17.2-44.5-1.8z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});