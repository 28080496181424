define("ember-svg-jar/inlined/flag.hn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#0073cf\" d=\"M0 0h512v512H0z\"/><path fill=\"#fff\" d=\"M0 170.7h512v170.6H0z\"/><g id=\"c\" fill=\"#0073cf\" transform=\"translate(256 256) scale(28.44446)\"><g id=\"b\"><path id=\"a\" d=\"M0-1l-.3 1 .5.1z\"/><use width=\"100%\" height=\"100%\" transform=\"scale(-1 1)\" xlink:href=\"#a\"/></g><use width=\"100%\" height=\"100%\" transform=\"rotate(72)\" xlink:href=\"#b\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(-72)\" xlink:href=\"#b\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(144)\" xlink:href=\"#b\"/><use width=\"100%\" height=\"100%\" transform=\"rotate(-144)\" xlink:href=\"#b\"/></g><use width=\"100%\" height=\"100%\" transform=\"translate(142.2 -45.5)\" xlink:href=\"#c\"/><use width=\"100%\" height=\"100%\" transform=\"translate(142.2 39.8)\" xlink:href=\"#c\"/><use width=\"100%\" height=\"100%\" transform=\"translate(-142.2 -45.5)\" xlink:href=\"#c\"/><use width=\"100%\" height=\"100%\" transform=\"translate(-142.2 39.8)\" xlink:href=\"#c\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});