define("ember-svg-jar/inlined/flag.ps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M237.1 0h493.5v493.5H237.1z\"/></clipPath></defs><g clip-path=\"url(#a)\" transform=\"translate(-246) scale(1.0375)\"><g fill-rule=\"evenodd\" stroke-width=\"1pt\"><path d=\"M0 0h987v164.5H0z\"/><path fill=\"#fff\" d=\"M0 164.5h987V329H0z\"/><path fill=\"#090\" d=\"M0 329h987v164.5H0z\"/><path fill=\"red\" d=\"M0 493.5l493.5-246.8L0 0v493.5z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});