define("ember-svg-jar/inlined/flag.ss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#078930\" d=\"M0 358.4h512V512H0z\"/><path fill=\"#fff\" d=\"M0 153.6h512v204.8H0z\"/><path d=\"M0 0h512v153.6H0z\"/><path fill=\"#da121a\" d=\"M0 179.2h512v153.6H0z\"/><path fill=\"#0f47af\" d=\"M0 0l433 256L0 512z\"/><path fill=\"#fcdd09\" d=\"M209 207.8L64.4 256l144.8 48.1-89.5-126v155.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});