define("ember-svg-jar/inlined/flag.dj", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M55.4 0H764v708.7H55.4z\"/></clipPath></defs><g fill-rule=\"evenodd\" clip-path=\"url(#a)\" transform=\"translate(-40) scale(.722)\"><path fill=\"#0c0\" d=\"M0 0h1063v708.7H0z\"/><path fill=\"#69f\" d=\"M0 0h1063v354.3H0z\"/><path fill=\"#fffefe\" d=\"M0 0l529.7 353.9L0 707.3V0z\"/><path fill=\"red\" d=\"M221.2 404.3l-42.7-30.8-42.4 31 15.8-50.3-42.4-31.2 52.4-.4 16.3-50.2 16.6 50 52.4.2-42.1 31.4 16 50.3z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});