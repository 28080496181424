define("ember-svg-jar/inlined/flag.sj", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#ef2b2d\" d=\"M0 0h512v512H0z\"/><path fill=\"#fff\" d=\"M128 0h128v512H128z\"/><path fill=\"#fff\" d=\"M0 192h512v128H0z\"/><path fill=\"#002868\" d=\"M160 0h64v512h-64z\"/><path fill=\"#002868\" d=\"M0 224h512v64H0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});