define("ember-svg-jar/inlined/flag.sn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\" stroke-width=\"1pt\"><path fill=\"#0b7226\" d=\"M0 0h170.7v512H0z\"/><path fill=\"#ff0\" d=\"M170.7 0h170.6v512H170.7z\"/><path fill=\"#bc0000\" d=\"M341.3 0H512v512H341.3z\"/></g><path fill=\"#0b7226\" d=\"M197 351.7l22-71.7-60.4-46.5h74.5l24.2-76 22.1 76H356L295.6 280l22.1 74-60.3-46.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});