define("ember-svg-jar/inlined/flag.sl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><rect width=\"384\" height=\"512\" rx=\"4.6\" ry=\"7.6\"/></clipPath></defs><g fill-rule=\"evenodd\" clip-path=\"url(#a)\" transform=\"scale(1.33333 1)\"><path fill=\"#0000cd\" d=\"M0 341.7h512V512H0z\"/><path fill=\"#fff\" d=\"M0 171.4h512v170.3H0z\"/><path fill=\"#00cd00\" d=\"M0 0h512v171.4H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});